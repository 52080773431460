import React, { ReactNode } from "react";
import { cdnPrefix, numeral } from "@/utils";
import "./index.css";
import classNames from "classnames";

interface RewardProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

function RewardModal({ isOpen, onClose, children }: RewardProps) {
  if (!isOpen) return null;
  return (
    <div className="reward-container fixed inset-0 z-[10004] flex items-center justify-center bg-[rgba(0,0,0,0.7)]">
      {/* 遮罩层 */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>

      {/* 弹出框 */}
      <div className="relative z-10 w-full max-w-lg">
        <div className="reward-content">{children}</div>
      </div>
    </div>
  );
}

export default RewardModal;
