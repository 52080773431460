"use client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import vapConfig from "@/vap/congratulations.json";
import { VapManager } from "@/utils/vap";
import { RewardModal } from "@/components/RewardModal";
import Img from "@/components/Img";
import { Button } from "@/components/Button";
import { formatNumber } from "accounting";
import { cdnPrefix, i18n } from "@/utils";
import { pngList } from "./png";

import "./index.css";

export const GiftPop = (props: any) => {
  const { isOpen = false, onClose, rewardList = [], params = "" } = props;

  const [isListVisible, setIsListVisible] = useState(true); // 控制列表的显示状态

  const [isListScaled, setIsListScaled] = useState(false); // 控制缩放状态

  const divRef = useRef<HTMLDivElement>(null);
  const vapManagerRef = useRef<VapManager | null>(null);
  useEffect(() => {
    if (!isOpen) return;
    // 使用唯一的 key 创建 VapManager 实例
    vapManagerRef.current = new VapManager("uniqueKey", {
      container: divRef.current as HTMLElement,
      src: "/video/congratulations.mp4",
      config: vapConfig,
      fps: 60, // 自定义配置
      width: 300,
      height: 300,
      loop: false,
      mute: true,
    });
    return () => {
      vapManagerRef.current?.destroy();
    };
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setIsListVisible(false);
      setIsListScaled(false);
      return;
    }

    // 在模态框打开后100毫秒后让列表淡入
    const timeout = setTimeout(() => {
      setIsListVisible(true);
      setIsListScaled(true); // 放大 1.2 倍
    }, 100);

    // 0.5 秒后恢复缩放大小
    const scaleTimeout = setTimeout(() => {
      setIsListScaled(false);
    }, 600);
    // 清除定时器
    return () => {
      clearTimeout(timeout);
      clearTimeout(scaleTimeout);
    };
  }, [isOpen]);

  const row = useMemo(() => {
    const len = rewardList?.length;
    return len < 3 ? len : 3;
  }, [rewardList]);

  return (
    <RewardModal isOpen={isOpen} onClose={onClose}>
      <div className="w-[5rem] m-[auto] mb-[1.5rem] text-center">
        <Img src={pngList.biaoti} alt="" className="inline-block" />
      </div>
      <div className="vap-box relative" ref={divRef}>
        <div className={`grid grid-cols-${row} mb-[1.5rem] z-[2] text-[#ffdb67]`}>
          {rewardList?.map((reward: any, index: number) => {
            return (
              <div
                className={`list text-center transition-all duration-1000 ${
                  isListVisible ? "opacity-100" : "opacity-0"
                } ${isListScaled ? "scale-125" : "scale-100"}`}
                key={index}
              >
                <Img className="max-w-[2rem] mx-auto" src={pngList.p01} alt="box.png" />
                <p className="text-[0.32rem] ">
                  {i18n.common("CURRENCY_SYMBOL")}
                  {formatNumber(reward?.num / 1000)}
                </p>
                <p className="text-[0.24rem] text-[#f1a14a]">{params}</p>
              </div>
            );
          })}
        </div>
      </div>
      <Button className="relative w-[5.5rem] block z-10" onClick={() => onClose()}>
        {i18n.common("BTN_CONFIRM")}
      </Button>
    </RewardModal>
  );
};
