import Vap from "video-animation-player";
import { VapConfig } from "video-animation-player/dist/type";

type PartialVapConfig = Partial<VapConfig>;

export class VapManager {
  static _instances: Record<string, Vap> = {};

  private key: string;

  private instance: Vap;

  constructor(key: string, options?: PartialVapConfig) {
    const config: PartialVapConfig = Object.assign(
      {
        // 同素材生成工具中配置的保持一致
        fps: 60,
        // 是否循环
        loop: true,
        // 起始播放时间点
        beginPoint: 0,
        // 精准模式
        accurate: true,
        mute: true,
        type: 1,
        precache: true,
      },
      options,
    );

    VapManager._instances[key] = new Vap(config);

    this.instance = VapManager._instances[key];

    this.key = key;

    return VapManager._instances[key];
  }

  on(event: string, fn: () => void) {
    this.instance.on(event, fn);
    return this.instance;
  }

  destroy() {
    this.instance.destroy();
    return this.instance;
  }

  pause(...argy: any) {
    this.instance.pause(...argy);
    return this.instance;
  }

  play(...argy: any) {
    this.instance.play(...argy);
    return this.instance;
  }
}
