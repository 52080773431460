import p01 from "@/assets/reward/p01.inline.png";
import p02 from "@/assets/reward/p02.inline.png";
import biaoti from "@/assets/reward/biaoti.inline.png";
import welcome from "@/assets/reward/welcome.inline.png";

export const pngList = {
  p01,
  p02,
  biaoti,
  welcome,
};
